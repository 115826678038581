import { Link } from 'gatsby';
import React, { useRef } from 'react';

const ContactForm = () => {

  const privacyModalRef = useRef();
  const processingModalRef = useRef();

  const onSubmit = (evt) => {
    evt.preventDefault();
    const formData = new FormData(evt.target);
    const options = {
      method: 'POST',
      body: JSON.stringify({
        name: formData.get('name'),
        mail: formData.get('mail'),
        message: formData.get('message'),
        'dataprivacy-disclaimer': formData.get('dataprivacy-disclaimer'),
        'processing-disclaimer': formData.get('processing-disclaimer'),
        receiver: 'info@strassburg-passage.de'
      }),
    };
    fetch('/sendmail', options)
      .then(res => res.json())
      .then(data => console.log(data))
      .catch(err => console.error(err))
  };

  return (
    <form
      style={{ padding: '2rem', display: 'flex', flexDirection: 'column' }}
      onSubmit={onSubmit}
      id="contact-form"
    >
      <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', flexWrap: 'wrap' }}>
        <input
          style={{ flex: '1' }}
          name="name"
          placeholder="Name*"
          required
          minLength={2}
          maxLength={36}
        />
        <input
          style={{ flex: '1' }}
          name="mail"
          placeholder="Email*"
          required
          type="email"
          pattern={/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/}
          minLength={2}
          maxLength={36}
        />
      </div>
      <textarea
        style={{ width: "100%", marginTop: '1rem' }}
        name="message"
        rows={6}
        placeholder="Nachricht*"
        required
        minLength={2}
        maxLength={256}
      />
      <div style={{ alignSelf: 'center' }}>
        <input style={{ marginTop: '1rem', marginBottom: '1rem' }} type="checkbox" name="dataprivacy-disclaimer" required />
        <label style={{ marginLeft: '1.5rem', cursor: 'pointer' }} htmlFor="dataprivacy-disclaimer" onClick={() => privacyModalRef.current.showModal()}>Datenschutz</label>
        <dialog ref={privacyModalRef} onClick={() => privacyModalRef.current.close()}>
          <h3>Zustimmung Datenschutz</h3>
          <p>
            Hiermit stimmen sie der Datenschutzrichtlinien dieser Webseite zu. Die Einwilligung zur Verarbeitung ihrer Daten kann jederzeit
            wiederrufen werden. Hierfür schreiben sie einfach eine Mail an{' '}
            <a href="mailto:datenschutz@strassburg-passage.de">datenschutz@strassburg-passage.de</a>.
          </p>
          <p>
            Detaillierte Informationen zum Datenschutz und zum Umgang mit personenbezogenen Daten finden Sie in der{' '}
            <Link to="/datenschutz">allgemeinen Datenschutzerklärung</Link> dieser Webseite.
          </p>
        </dialog>
        <input style={{ marginLeft: '4rem', marginTop: '1rem', marginBottom: '1rem' }} type="checkbox" name="processing-disclaimer" required />
        <label style={{ marginLeft: '1.5rem', cursor: 'pointer' }} htmlFor="processing-disclaimer" onClick={() => processingModalRef.current.showModal()}>Verarbeitung</label>
        <dialog ref={processingModalRef} onClick={() => processingModalRef.current.close()}>
          <h3>Zustimmung Datenverarbeitung</h3>
          <p>
            Die personenbezogenen Daten, die Sie uns im Rahmen dieser Kontaktanfrage zur Verfügung stellen, werden nur für die Beantwortung Ihrer
            Anfrage bzw. Kontaktaufnahme und für die damit verbundene technische Administration verwendet. Die Weitergabe an Dritte findet nicht
            statt.
          </p>
          <p>
            Sie haben das Recht, eine erteilte Einwilligung mit Wirkung für die Zukunft jederzeit zu widerrufen. In diesem Fall werden Ihre
            personenbezogenen Daten umgehend gelöscht.
          </p>
          <p>
            Ihre personenbezogenen Daten werden auch ohne Ihren Widerruf gelöscht, wenn wir Ihre Anfrage bearbeitet haben oder Sie die hier
            erteilte Einwilligung zur Speicherung widerrufen. Dies geschieht auch, wenn die Speicherung aus sonstigen gesetzlichen Gründen
            unzulässig ist.
          </p>
          <p>
            Sie können sich jederzeit über die zu Ihrer Person gespeicherten Daten informieren. Die Einwilligung zur Verarbeitung ihrer Daten kann
            jederzeit wiederrufen werden. Hierfür schreiben sie einfach eine Mail an
            <a href="mailto:datenschutz@strassburg-passage.de">datenschutz@strassburg-passage.de</a>.
          </p>
          <p>
            Detaillierte Informationen zum Datenschutz und zum Umgang mit personenbezogenen Daten finden Sie in der{' '}
            <Link to="/datenschutz">allgemeinen Datenschutzerklärung</Link> dieser Webseite.
          </p>
        </dialog>
      </div>
      <button
        style={{ marginTop: '1rem !important' }}
        type="submit"
      >
        ABSENDEN
      </button>
    </form>
  );
};

export default ContactForm;
