import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Helmet from 'react-helmet';

import { Header } from './header';
import Footer from './footer';

import 'antd/lib/menu/style';
import 'antd/lib/layout/style';
import 'antd/lib/icon/style';
import 'antd/lib/col/style';
import 'antd/lib/row/style';
import 'antd/lib/card/style';
import 'antd/lib/avatar/style';
import 'antd/lib/list/style';
import 'antd/lib/select/style';
import 'antd/lib/drawer/style';
import 'antd/lib/carousel/style';

import styles from './layout.module.css';

import './reset.css';
import './index.css';

const renderHelmet = (data) => {
  if (!data.node) {
    return (
      <Helmet>
        <title itemProp="name">{data.title}</title>
      </Helmet>
    );
  }
  const {
    node: { name, path, description, header },
    title,
    baseUrl,
  } = data;
  return (
    <Helmet>
      <title itemProp="name">
        {name} {title}
      </title>
      <meta name="description" content={description || header ? `${title} - ${description || header}` : `${title} - Unterseite`} />
      <link key="meta-x-default" rel="alternate" hreflang="x-default" href={`${baseUrl}${path}`} />
    </Helmet>
  );
};

export default ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              meta {
                title
                baseUrl
              }
              staticRoutes {
                order
                path
                name
                description
                hideInNav
              }
            }
          }
          pages: allContentfulSeite {
            edges {
              node {
                order
                path
                name
                header
                description
                hideInNav
              }
            }
          }
          galleries: allContentfulGallerie {
            edges {
              node {
                path
                name
                header
              }
            }
          }
        }
      `}
      render={({ pages, site, galleries }) => {
        const { meta, staticRoutes } = site.siteMetadata;
        const mapped = [...pages.edges, ...galleries.edges, ...staticRoutes.map((node) => ({ node }))];
        const paths = mapped
          .filter(({ node }) => !node.hideInNav)
          .sort((a, b) => (a.node.order || 10) - (b.node.order || 10))
          .reduce((red, { node: { path, ...rest } }) => ({ ...red, [path]: rest }), {});
        const currentPath = mapped.find(({ node }) => typeof window !== 'undefined' && window.location && node.path === window.location.pathname);
        return (
          <>
            {renderHelmet({ ...meta, ...currentPath })}
            <div className={styles.root}>
              <Header paths={paths} />
              {children}
              <Footer />
            </div>
          </>
        );
      }}
    />
  );
};
