import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { List, Avatar } from 'antd';
import { graphql, StaticQuery, Link } from 'gatsby';
// import ContactForm from '@bit/jroehl.drylib.components.contact-form';
import ContactForm from './ContactForm';

import styles from './footer.module.css';

const links = [{ icon: 'facebook', url: 'https://facebook.com/strassburgpassage' }, { icon: 'youtube', url: 'https://youtube.com/strassburgpassage' }];

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          site {
            siteMetadata {
              meta {
                baseUrl
              }
              endpoint {
                url
                receiver
              }
              address {
                name
                street
                city
                phone
                mail
              }
            }
          }
        }
      `}
      render={({ site }) => {
        const { address, meta, endpoint } = site.siteMetadata;
        const { name, street, city, phone, mail } = address.find(({ name }) => name.includes('Straßburg-Passage'));
        return (
          <div className={styles.root}>
            <div className={styles.form}>
              <h4 id="kontakt">KONTAKT</h4>
              <ContactForm />
            </div>
            <hr />
            <div className={styles.list}>
              <List
                itemLayout="vertical"
                size="large"
                dataSource={[
                  {
                    title: <div className={styles.title}>{name}</div>,
                    social: (
                      <div className={styles.contact}>
                        <p>
                          <span>Social</span>
                          <span>
                            {links.map(({ icon, url }) => (
                              <a key={icon} href={url} target="_blank" rel="noopener noreferrer">
                                <LegacyIcon type={icon} style={{ marginRight: 8 }} />
                              </a>
                            ))}
                          </span>
                        </p>
                      </div>
                    ),
                    description: (
                      <div className={styles.contact}>
                        <p>
                          <span>Adresse</span>
                          <a
                            href="https://www.google.com/maps/place/Stra%C3%9Fburg-Passage+G%C3%B6rlitz/@51.1503729,14.9850718,17z/data=!3m1!4b1!4m5!3m4!1s0x4708dc257aa4fa4b:0x70142286df00a3ee!8m2!3d51.1503729!4d14.9850718?hl=de"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {street}, {city}
                          </a>
                        </p>
                      </div>
                    ),
                    content: (
                      <div className={styles.contact}>
                        <p>
                          <span>Telefon</span>
                          <a href={`tel:${phone.replace(/ |-/g, '')}`} target="_blank" rel="noopener noreferrer">
                            {phone}
                          </a>
                        </p>
                        <p>
                          <span>Email</span>
                          <a href={`mailto:${mail}`} target="_blank" rel="noopener noreferrer">
                            {mail}
                          </a>
                        </p>
                      </div>
                    ),
                    law: (
                      <div className={styles.contact}>
                        <p>
                          <span>Rechtlich</span>
                          <span className={styles.lawLinks}>
                            <Link to="/datenschutz">Datenschutz</Link>
                            <Link to="/impressum">Impressum</Link>
                          </span>
                        </p>
                      </div>
                    ),
                    webdesign: (
                      <div className={styles.contact}>
                        <p>
                          <span>Umsetzung</span>
                          <span>
                            <a href="https://hinterland.software" targe="_blank">
                              &copy; 2013 - {new Date().getFullYear()} HINTERLAND software
                            </a>
                          </span>
                        </p>
                      </div>
                    ),
                  },
                ]}
                renderItem={item => (
                  <List.Item key={item.title}>
                    <List.Item.Meta avatar={<Avatar src={item.avatar} />} title={<a href={item.href}>{item.title}</a>} description={item.description} />
                    {item.content}
                    {item.social}
                    {item.law}
                    {item.webdesign}
                  </List.Item>
                )}
              />
            </div>
          </div>
        );
      }}
    />
  );
};
